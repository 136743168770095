import {createContext, useMemo, useState} from "react";

const AppContext = createContext({
    points: [],
    setPoints: () => {},
    checked: [],
    setChecked: () => {},
    sections: [],
    setSections: () => {},
    comment: "",
    setComment: () => {}
});

function AppProvider({ children }) {
    const [points, setPoints] = useState([]);
    const [checked, setChecked] = useState([]);
    const [sections, setSections] = useState([]);
    const [comment, setComment] = useState("");

    const value = useMemo(() => ({
        points, setPoints, checked, setChecked, sections, setSections, comment, setComment
    }), [points, checked, sections, comment])

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    )
}

export { AppContext, AppProvider };